<template>
  <table v-if="!$_.isEmpty(plugins)" class="table is-stretched is-size-7">
    <b-loading :active="pluginOrThemeUpdating" :is-full-page="false" />
    <tbody>
      <tr v-for="plugin in plugins" :key="plugin.id">
        <td>
          <p>
            <a
              :href="`https://wordpress.org/plugins/${plugin.id.split('/')[0]}`"
              target="_blank"
              class="has-text-dark has-text-weight-semibold"
            >
              <u>{{ plugin.name }}</u>
            </a>
            <span>&nbsp;(v{{ plugin.version }})</span>
          </p>
          <p v-if="!plugin.message" class="is-size-7 has-text-danger">
            {{ plugin.message }}
          </p>
        </td>
        <td
          class="has-text-right"
          width="1px"
          :style="{ whiteSpace: 'nowrap' }"
        >
          <a
            v-if="plugin.newVersion"
            class="has-text-dark"
            :class="{
              'has-cursor-wait': plugin.isProcessing
            }"
            @click="updatePlugin(plugin)"
          >
            <b-icon
              icon="wrench"
              size="is-small"
              type="is-dark"
              class="has-margin-right-50"
            />
            <u
              >{{ plugin.isProcessing ? "Updating" : "Update" }}
              to
              <strong>v{{ plugin.newVersion }}</strong>
            </u>
          </a>
          <p v-else class="has-text-success">
            <b-icon icon="check" size="is-small" class="has-margin-right-50" />
            <span>Up to date</span>
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  <div v-else>This site has no plugins installed.</div>
</template>

<script>
export default {
  name: "SiteWordpressPluginsView",
  props: {
    siteId: {
      required: true,
      type: String
    }
  },
  computed: {
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    },
    themes() {
      return this.$_.toArray(this.$_.get(this.site, "wordpress.themes"));
    },
    plugins() {
      return this.$_(this.$_.get(this.site, "wordpress.plugins", []))
        .toArray()
        .orderBy("name", "asc")
        .value();
    },
    pluginOrThemeUpdating() {
      return !!(
        this.$_.find(this.themes, i => i.isProcessing) ||
        this.$_.find(this.plugins, i => i.isProcessing)
      );
    }
  },
  methods: {
    updatePlugin(plugin) {
      if (this.pluginOrThemeUpdating) return;

      this.$toast.open({
        message: `Update initiated`
      });
      this.$store
        .dispatch("sites/updateWordpressPlugin", {
          siteId: this.siteId,
          slug: plugin.id
        })
        .catch(() => {
          this.$toast.open({
            message: `Error starting update`,
            type: "is-danger"
          });
        });
    }
  }
};
</script>
